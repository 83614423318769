var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AdminStatisticsFilter"},[_c('div',{class:("statistics-block __general " + (_vm.$loading ? '__loading' : '__loaded'))},[_c('Spacer',{attrs:{"num":"1"}}),_c('Title',{attrs:{"label":_vm.title}}),_c('Spacer',{attrs:{"num":"1"}}),_c('div',{staticClass:"ReportInfo"},[_c('LargeButton',{attrs:{"label":_vm.$locale['start_date'],"icon":"false","rightDesc":_vm.date_locale(_vm.startDate)},on:{"click":function($event){_vm.view({
            title: _vm.$locale['select_start_date'],
            component: function () { return import('../widgets/Calendar.vue'); },
            class: 'menu bottom-mbl',
            closeOutside: true,
            cardStyle: { 'min-height': 'calc(var(--screen-height)/4)' },
            date: _vm.startDate,
            animation: 'bottomIn',
            onApply: function(date) {
              _vm.setQuery({ startDate: date, period: 'custom', endDate: _vm.endDate });
            },
            onShorcut: function(shorcut) {
              _vm.setQuery({ period: shorcut, startDate: undefined, endDate: undefined });
            },
          })}}}),_c('LargeButton',{attrs:{"label":_vm.$locale['end_date'],"icon":"false","rightDesc":_vm.date_locale(_vm.endDate)},on:{"click":function($event){_vm.view({
            title: _vm.$locale['select_end_date'],
            component: function () { return import('../widgets/Calendar.vue'); },
            class: 'menu bottom-mbl',
            closeOutside: true,
            cardStyle: { 'min-height': 'calc(var(--screen-height)/4)' },
            date: _vm.endDate,
            animation: 'bottomIn',
            onApply: function(date) {
              _vm.setQuery({ endDate: date, period: 'custom', startDate: _vm.startDate });
            },
            onShorcut: function(shorcut) {
              _vm.setQuery({ period: shorcut, startDate: undefined, endDate: undefined });
            },
          })}}}),_vm._t("default")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }