<template>
  <div class="AdminStatisticsFilter">
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <Spacer num="1" />
      <Title :label="title" />
      <Spacer num="1" />
      <div class="ReportInfo">
        <LargeButton
          :label="$locale['start_date']"
          icon="false"
          :rightDesc="date_locale(startDate)"
          @click="
            view({
              title: $locale['select_start_date'],
              component: () => import('../widgets/Calendar.vue'),
              class: 'menu bottom-mbl',
              closeOutside: true,
              cardStyle: { 'min-height': 'calc(var(--screen-height)/4)' },
              date: startDate,
              animation: 'bottomIn',
              onApply: function(date) {
                setQuery({ startDate: date, period: 'custom', endDate });
              },
              onShorcut: function(shorcut) {
                setQuery({ period: shorcut, startDate: undefined, endDate: undefined });
              },
            })
          "
        />

        <LargeButton
          :label="$locale['end_date']"
          icon="false"
          :rightDesc="date_locale(endDate)"
          @click="
            view({
              title: $locale['select_end_date'],
              component: () => import('../widgets/Calendar.vue'),
              class: 'menu bottom-mbl',
              closeOutside: true,
              cardStyle: { 'min-height': 'calc(var(--screen-height)/4)' },
              date: endDate,
              animation: 'bottomIn',
              onApply: function(date) {
                setQuery({ endDate: date, period: 'custom', startDate });
              },
              onShorcut: function(shorcut) {
                setQuery({ period: shorcut, startDate: undefined, endDate: undefined });
              },
            })
          "
        />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  props: ["statistics", "title"],
};
</script>
